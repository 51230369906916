import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Section from '../Section';
import SectionTitle from '../SectionTitle';

type Props = {};

const OurOffer = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-24">
          <div className="prose bg-emerald-400 p-12 prose-h2:text-blue-900 prose-p:text-blue-900">
            <h2 className="mt-0">{t('page.homepage.our_offer.title')}</h2>
            <p>{t('page.homepage.our_offer.text')}</p>
          </div>
          <div className="lg:col-span-2">
            <div className="prose">
              <SectionTitle>{t('page.homepage.our_offer.subtitle')}</SectionTitle>
              <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-16">
                <div>
                  <StaticImage
                    src="../../images/api.png"
                    alt=" "
                    placeholder="none"
                    layout="fixed"
                    width={196}
                    formats={['auto', 'webp', 'avif']}
                  />

                  <h3>{t('page.homepage.our_offer.offer_1.title')}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: t('page.homepage.our_offer.offer_1.text') }}
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../images/platform.png"
                    alt=" "
                    placeholder="none"
                    layout="fixed"
                    width={196}
                    formats={['auto', 'webp', 'avif']}
                  />
                  <h3>{t('page.homepage.our_offer.offer_2.title')}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: t('page.homepage.our_offer.offer_2.text') }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default OurOffer;
