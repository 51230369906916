import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const ListCheckItem = ({ children }: Props) => {
  return (
    <li className="flex gap-2">
      <CheckCircleIcon className="h-8 w-8 flex-shrink-0 text-emerald-500" />
      <span>{children}</span>
    </li>
  );
};

export default ListCheckItem;
