import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Container from '../Container';
import ListCheckItem from '../ListCheckItem';
import Section from '../Section';
import SectionTitle from '../SectionTitle';

type Props = {};

const WhyUs = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Section className="bg-slate-50">
      <Container>
        <div className="prose">
          <SectionTitle center>{t('page.homepage.why_us.title')}</SectionTitle>
          <div className="flex flex-col gap-8 md:mt-16 md:gap-24">
            <div className="grid grid-cols-1 items-center gap-6 md:grid-cols-2 md:gap-16">
              <div>
                <div
                  className="md:pr-12"
                  data-sal="slide-right"
                  style={{ '--sal-duration': '.5s' } as React.CSSProperties}
                >
                  <StaticImage
                    src="../../images/why1.png"
                    alt=" "
                    placeholder="none"
                    layout="constrained"
                    formats={['auto', 'webp', 'avif']}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <h3 className="mb-12 mt-0">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('page.homepage.why_us.block_1_title') }}
                  />
                </h3>
                <div className="not-prose">
                  <ul className="flex flex-col gap-4">
                    <ListCheckItem>{t('page.homepage.why_us.block_1_list_1')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_1_list_2')}</ListCheckItem>
                  </ul>
                </div>

                <p className="font-semibold">{t('page.homepage.why_us.block_1_text')}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-16">
              <div className="md:col-start-2 md:row-start-1">
                <div
                  className="md:pl-12"
                  data-sal="slide-right"
                  style={{ '--sal-duration': '.5s' } as React.CSSProperties}
                >
                  <StaticImage
                    src="../../images/why2.png"
                    alt=" "
                    placeholder="none"
                    layout="constrained"
                    formats={['auto', 'webp', 'avif']}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col md:col-start-1 md:row-start-1">
                <h3 className="mb-12 mt-0">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('page.homepage.why_us.block_2_title') }}
                  />
                </h3>
                <div className="not-prose">
                  <ul className="flex flex-col gap-4">
                    <ListCheckItem>{t('page.homepage.why_us.block_2_list_1')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_2_list_2')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_2_list_3')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_2_list_4')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_2_list_5')}</ListCheckItem>
                  </ul>
                </div>

                <p className="font-semibold">{t('page.homepage.why_us.block_2_text')}</p>
              </div>
            </div>
            {/* <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-16">
              <div>
                <div
                  className="md:pr-12"
                  data-sal="slide-right"
                  style={{ '--sal-duration': '.5s' } as React.CSSProperties}
                >
                  <StaticImage
                    src="../../images/why3.png"
                    alt=" "
                    placeholder="none"
                    layout="constrained"
                    formats={['auto', 'webp', 'avif']}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <h3 className="mt-0 mb-12">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('page.homepage.why_us.block_3_title') }}
                  />
                </h3>
                <div className="not-prose">
                  <ul className="flex flex-col gap-4">
                    <ListCheckItem>{t('page.homepage.why_us.block_3_list_1')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_3_list_2')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_3_list_3')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_3_list_4')}</ListCheckItem>
                    <ListCheckItem>{t('page.homepage.why_us.block_3_list_5')}</ListCheckItem>
                  </ul>
                </div>

                <p className="font-semibold">{t('page.homepage.why_us.block_3_text')}</p>
              </div>
            </div> */}
          </div>

          <div className="mt-12 flex flex-col justify-center gap-2 sm:flex-row sm:items-center">
            <Button to="/contact" variant="outline-dark" size="lg" className="group">
              {t('button.contact')}
              <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
            </Button>

            <Button to="/services" variant="secondary" size="lg" className="group">
              {t('button.services')}
              <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
            </Button>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default WhyUs;
