import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Hero from '../components/homepage/Hero';
import WhoAreWe from '../components/homepage/WhoAreWe';
import OurOffer from '../components/homepage/OurOffer';
import WhyUs from '../components/homepage/WhyUs';
import Pricing from '../components/homepage/Pricing';
import CtaNewProject from '../components/CtaNewProject';
const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <WhoAreWe />
      <WhyUs />
      <OurOffer />
      <Pricing />
      <CtaNewProject />
      {/* <Testimonials /> */}
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  return <Seo location={location} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
