import { ArrowRightCircleIcon, ArrowSmallRightIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Container from '../Container';
import HeaderSpacer from '../HeaderSpacer';
import HeroBackground from '../HeroBackground';

type Props = {};

const Hero = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col overflow-hidden bg-blue bg-gradient-to-b from-blue to-blue-800 py-4 sm:py-16">
      <HeaderSpacer />
      <HeroBackground />
      <div className="relative my-auto overflow-hidden">
        <Container>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-16 xl:items-center">
            <div className="prose prose-invert order-2 prose-h1:text-2xl md:order-1 prose-h1:md:text-4xl prose-p:md:text-lg prose-h1:xl:text-5xl">
              <h1
                className="m-0 text-white"
                data-sal="slide-up"
                style={{ '--sal-duration': '.5s' } as React.CSSProperties}
              >
                {t('page.homepage.hero.title')}
              </h1>
              <h2 className="mt-6 text-xl">{t('page.homepage.hero.subtitle')}</h2>
              <p>{t('page.homepage.hero.text')}</p>
              <div className="not-prose mb-8 flex flex-col gap-2 sm:flex-row sm:items-center">
                {/* <Button to="/use-cases" as="a" size="lg" variant="outline-light" className="group">
                  {t('button.use-cases')}
                  <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
                </Button> */}
                <Button to="/services" variant="secondary" size="lg" className="group">
                  {t('button.services')}
                  <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
                </Button>
              </div>
            </div>
            <div className="order-1 md:order-2">
              <div className="2xl:-mr-[5vw]">
                <StaticImage
                  src="../../images/hero.png"
                  alt=" "
                  placeholder="none"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
