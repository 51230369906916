import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Section from '../Section';
import SectionTitle from '../SectionTitle';

type Props = {};

const WhoAreWe = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-[auto_1fr] md:gap-24">
          <div>
            <StaticImage
              src="../../images/who-are-we.png"
              alt=" "
              placeholder="none"
              layout="constrained"
              width={320}
              formats={['auto', 'webp', 'avif']}
            />
          </div>
          <div>
            <div className="prose">
              <SectionTitle>{t('page.homepage.who_we_are.title')}</SectionTitle>
              <p>
                <strong>{t('page.homepage.who_we_are.text_1')}</strong>
              </p>
              <p>{t('page.homepage.who_we_are.text_2')}</p>
              <p>
                <strong>{t('page.homepage.who_we_are.text_3')}</strong>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default WhoAreWe;
