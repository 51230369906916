import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Container from '../Container';
import Section from '../Section';
import SectionTitle from '../SectionTitle';

type Props = {};

const Pricing = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Section className="bg-slate-50">
      <Container>
        <div className="prose prose-h2:mt-0">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-24">
            <div className="flex-shrink-0">
              <StaticImage
                src="../../images/pricing.png"
                alt=" "
                placeholder="none"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                width={320}
              />
            </div>
            <div className="lg:col-span-2">
              <SectionTitle>{t('page.homepage.pricing.title')}</SectionTitle>
              <div dangerouslySetInnerHTML={{ __html: t('page.homepage.pricing.text') }} />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Pricing;
