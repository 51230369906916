import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Container from './Container';

type Props = {};

const CtaNewProject = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="relative bg-emerald-500 py-16">
      <Container>
        <div>
          <div className="prose text-center text-blue prose-h3:mb-1  prose-h3:text-blue">
            <h3>{t('contact_cta.title')}</h3>
            <p>{t('contact_cta.text')}</p>
            <p>
              <Button to="/contact" variant="dark" size="lg" className="group">
                {t('button.contact')}
                <ArrowRightCircleIcon className="h-6 w-6 transition-transform group-hover:translate-x-1" />
              </Button>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CtaNewProject;
